export const DYNAMIC_PRICING_ROUTES = {
  ROOT: 'dynamic-pricing',
  PRICE_RECOMMENDATIONS: 'price-recommendations',
  PRICE_COMPARISON: 'price-comparison',
  PRICING_STRATEGY: 'pricing-strategy',
};

export const DYNAMIC_PRICING_STEPS_URL = {
  generateURL: (step: string) => `/${DYNAMIC_PRICING_ROUTES.ROOT}/${step}`,
};
