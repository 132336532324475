export const MARKET_OVERVIEW_ROUTES = {
  ROOT: 'market-overview',
  DASHBOARD: 'dashboard',
  SHOP_OVERVIEW: 'shop-overview',
  MARKET_EXPLORER: 'market-explorer',
};

export const MARKET_OVERVIEW_STEPS_URL = {
  generateURL: (step: string) => `/${MARKET_OVERVIEW_ROUTES.ROOT}/${step}`,
};
