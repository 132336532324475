import { ADMINISTRATION_ROUTES } from './administration-routes.constant';
import { ANALYTICS_ROUTES } from './analytics-routes.constant';
import { DYNAMIC_PRICING_ROUTES } from './dynamic-pricing-routes.constants';
import { EXPORTS_ROUTES } from './exports-routes.constants';
import { MARKET_OVERVIEW_ROUTES } from './market-overview-routes.constants';
import { PRODUCT_OVERVIEW_ROUTES } from './product-overview-routes.constants';
export const APP_ROUTES = {
  ROOT: 'contract',
  MARKET_OVERVIEW: MARKET_OVERVIEW_ROUTES,
  PRODUCT_OVERVIEW: PRODUCT_OVERVIEW_ROUTES,
  PRODUCTS: 'products',
  SHOPS: 'shops',
  PRICING_STRATEGY_EXPLAIN_WHY: 'pricing-strategy-explain-why',
  DYNAMIC_PRICING: DYNAMIC_PRICING_ROUTES,
  EXPORTS: EXPORTS_ROUTES,
  ORGANIZATION: ADMINISTRATION_ROUTES,
  ANALYTICS: ANALYTICS_ROUTES,
};

export const ROUTES = {
  APP: APP_ROUTES,
  INACTIVE_ACCOUNT: 'inactive-account',
  INVALID_ACCOUNT: 'invalid-account',
};
